
import { defineComponent, onMounted } from "vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
import Entitytable from "@/components/EntityTable.vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import FilterBox from "@/components/FilterBox.vue";

export default defineComponent({
  name: "kt-subscription-list",
  components: {
    Entitytable,
    FilterBox
  },
  methods: {
    downloadPayouts() {
      ApiService.download("payouts/export").then((response) => {
        console.log(response);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.click();
      });
    },
  },
  data() {
    return {
      filters: {},
      columnData: [
        {
          label: "ID",
          name: "id",
        },
        {
          label: "Offer",
          name: "name",
        },
        {
          label: "Vertical",
          name: "vertical",
        },
        {
          label: "Country",
          name: "country",
        },
        {
          label: "Description",
          name: "description",
        },
        {
          label: "Flow",
          name: "flow",
        },
        {
          label: "Access",
          name: "access",
        },
      ],
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Offers");
    });

    const store = useStore();

    return { store };
  },
});
